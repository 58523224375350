import React from "react"
import tw from "twin.macro"

import { Localization } from "@src/localization"
import InfoBar from "@src/components/infoBar"

const MappingNavigation = (): React.ReactElement => {
  const content = React.useContext(Localization).mapping

  return (
    <div>
      {content.heading && (
        <h3
          tw="text-gray-600 font-bold mt-4"
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            __html: content.heading,
          }}
        ></h3>
      )}
      <div css={[tw`flex flex-row w-full pt-4 justify-between items-center`]}>
        <div>
          {content.title && <h2 tw="text-4xl mb-4">{content.title}</h2>}
        </div>
        <div>{content.info && <InfoBar message={content.info} />}</div>
      </div>
    </div>
  )
}

export default MappingNavigation
