import React from "react"
import { Upload, Button, Checkbox, Form, Radio } from "antd"
import tw from "twin.macro"
import css from "@emotion/css"
import MultiSelect from "react-multi-select-component"
import { useQuery, useMutation } from "@apollo/client"

import { Location, Provider } from "@src/queries/user"
import { MultiSelectOption } from "@src/components/fileUpload/tagUploadingFile"
import Loading from "@src/components/loading"
import CustomModal from "@src/components/ui/modal"
import {
  Gallery,
  GET_GALLERY,
  UploadFileInput,
  UPLOAD_IN_GALLERY,
} from "@src/queries/gallery"
import { LocationGalleryMapping } from "@src/queries/locationGalleryMapping"

const ONE_KB = 1024 // 1KB = 1024 Bytes

export enum MappingLogoImage {
  BACK_LOGO = "backLogo",
  FRONT_LOGO = "frontLogo",
  BACK_IMAGE = "backPhoto",
  FRONT_IMAGE = "frontPhoto",
}

interface ImageModalProps {
  visible: boolean
  mappingLogoImage?: MappingLogoImage
  selectedLocation?: Location
  locationGalleryMapping?: LocationGalleryMapping
  locations?: Location[]
  providers?: Provider[]
  onCancel: () => void
  onSubmit: (
    imageName: string,
    imageUrl: string,
    locKeys: string[]
  ) => Promise<void>
  setMappingLogoImage: (mappingLogoImage: MappingLogoImage | undefined) => void
}

interface FooterProps {
  loading: boolean
  mappingLogoImage?: MappingLogoImage
  onCancel: () => void
  onSubmit: () => void
}

export const ImageModal: React.FC<ImageModalProps> = ({
  visible,
  selectedLocation,
  mappingLogoImage,
  locationGalleryMapping,
  locations = [],
  providers = [],
  onCancel,
  onSubmit,
  setMappingLogoImage,
}) => {
  const [form] = Form.useForm()

  const [imageUrl, setImageUrl] = React.useState<string | null>()
  const [imageName, setImageName] = React.useState<string>("")
  const [originFile, setOriginFile] = React.useState<File>()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [isFileError, setIsFileError] = React.useState<boolean>(false)
  const [isScaleToFix, setIsScaleToFix] = React.useState<boolean>(false)
  const [isMultipleLocations, setIsMultipleLocations] = React.useState<boolean>(
    false
  )

  const [selectedLocationKeys, setSelectedLocationKeys] = React.useState<
    MultiSelectOption[]
  >([])
  const [selectedTagLocationKeys, setSelectedTagLocationKeys] = React.useState<
    MultiSelectOption[]
  >([])
  const [selectedProviderKeys, setSelectedProviderKeys] = React.useState<
    MultiSelectOption[]
  >([])

  // get gallery
  const getGalleryResp = useQuery(GET_GALLERY, {
    variables: {
      uploadType: 1,
    },
  })

  const images: Gallery[] =
    getGalleryResp && getGalleryResp.data && getGalleryResp.data.images

  // upload image mutation
  const [uploadImageMutation] = useMutation(UPLOAD_IN_GALLERY)

  React.useEffect(() => {
    if (locationGalleryMapping) {
      let url = null
      if (mappingLogoImage === MappingLogoImage.BACK_LOGO) {
        url = locationGalleryMapping.backLogoPublicUrl
      } else if (mappingLogoImage === MappingLogoImage.FRONT_LOGO) {
        url = locationGalleryMapping.frontLogoPublicUrl
      } else if (mappingLogoImage === MappingLogoImage.BACK_IMAGE) {
        url = locationGalleryMapping.backPhotoPublicUrl
      } else if (mappingLogoImage === MappingLogoImage.FRONT_IMAGE) {
        url = locationGalleryMapping.frontPhotoPublicUrl
      }

      setImageUrl(url)
    }
  }, [locationGalleryMapping, mappingLogoImage])

  React.useEffect(() => {
    if (originFile) {
      setImageUrl(URL.createObjectURL(originFile))
    }
  }, [originFile])

  // show loading
  if (getGalleryResp.loading) {
    return <Loading withOverlay />
  }

  // filter images based on section
  let sortedImages = images.filter(i => i.galleryCoverKey)

  if (sortedImages && sortedImages.length > 0) {
    // sort by providers
    if (selectedProviderKeys && selectedProviderKeys.length > 0) {
      sortedImages = sortedImages.sort(a => {
        const found =
          a.listAcctKey &&
          a.listAcctKey.some(r =>
            selectedProviderKeys.map(l => l.value).includes(r)
          )

        if (found) return -1
        return 1
      })
    }
  }

  const locationOptions: MultiSelectOption[] = locations.map(location => {
    return {
      label: location.orgName,
      value: location.locKey,
    }
  })

  const locationMultipleOptions: MultiSelectOption[] = locations
    .filter(loc => loc.locKey !== selectedLocation?.locKey)
    .map(location => {
      return {
        label: location.orgName,
        value: location.locKey,
      }
    })

  const providerOptions: MultiSelectOption[] = providers.map(provider => {
    return {
      label: `${provider.title} ${provider.firstName} ${provider.lastName} ${provider.degree}`,
      value: provider.acctKey,
    }
  })

  const onChangeFile = (info: any) => {
    const file = info.file.originFileObj

    if (file.size < ONE_KB * 45 || file.size > ONE_KB * 1024 * 10) {
      setIsFileError(true)
    } else {
      setOriginFile(file)
      setIsFileError(false)
    }
  }

  const handleCancel = () => {
    onCancel()
    setImageUrl(undefined)
    setMappingLogoImage(undefined)
    setOriginFile(undefined)
    setIsFileError(false)
    setIsScaleToFix(false)
    setIsMultipleLocations(false)
    setSelectedLocationKeys([])
    setSelectedProviderKeys([])
    setSelectedTagLocationKeys([])
    form.resetFields()
  }

  const handleSubmit = () => {
    setLoading(true)
    const locKeys = selectedLocationKeys.map(loc => loc.value)
    locKeys.push(selectedLocation?.locKey || null)

    if (!originFile) {
      if (imageUrl) {
        onSubmit(imageName, imageUrl, locKeys)
          .then(() => handleCancel())
          .catch(error => console.log(error))
      }
    } else {
      const uploadImageBody: UploadFileInput = {
        LocKeys: selectedLocationKeys.map(l => l.value),
        AcctKeys: selectedProviderKeys.map(p => p.value),
        File: originFile,
        UploadType: 1,
      }

      uploadImageMutation({
        variables: {
          body: uploadImageBody,
        },
        refetchQueries: [
          {
            query: GET_GALLERY,
            variables: {
              uploadType: 1,
            },
          },
        ],
      })
        .then(result => {
          onSubmit(originFile.name, result.data.image.fileUrl, locKeys)
            .then(() => handleCancel())
            .catch(error => console.log(error))
        })
        .catch(e => console.log(e))
    }

    setLoading(false)
  }

  return (
    <CustomModal
      width={1100}
      isVisible={visible}
      onCancel={handleCancel}
      title={<Title />}
      footer={
        <Footer
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          loading={loading}
          mappingLogoImage={mappingLogoImage}
        />
      }
    >
      <div tw="px-16 pb-8">
        <p tw="text-sm text-black font-bold my-4">
          Asset Currently Mapped to this location:{" "}
          {selectedLocation?.orgName || "N/A"}
        </p>
        <Form form={form}>
          <div tw="flex justify-between">
            <div tw="w-5/12">
              <div
                css={[
                  tw`flex border border-dashed items-center justify-center bg-no-repeat bg-center border-gray-500 text-xs text-center w-full h-64`,
                  css`
                    line-height: 1em;
                    background-size: ${isScaleToFix ? "contain" : "cover"};

                    ${imageUrl
                      ? css`
                          background-image: url(${imageUrl});
                        `
                      : tw`bg-gray-400`}
                  `,
                ]}
              ></div>

              <div tw="my-4 flex items-center justify-between">
                <Checkbox
                  css={css`
                    .ant-checkbox-checked .ant-checkbox-inner {
                      ${tw`bg-black border-black`}
                    }

                    .ant-checkbox .ant-checkbox-inner,
                    .ant-checkbox-checked::after {
                      border-color: black;
                    }

                    ${tw`text-xs text-black`}
                  `}
                  checked={isScaleToFix}
                  onChange={() => setIsScaleToFix(!isScaleToFix)}
                >
                  Scale to fit.
                </Checkbox>

                <Upload
                  accept=".jpg"
                  showUploadList={false}
                  onChange={onChangeFile}
                  customRequest={() => {
                    //
                  }}
                >
                  <Button
                    css={css`
                      width: 206px;
                      border: solid 1px #979797;
                      background-color: #d8d8d8;

                      ${tw`text-black font-bold`}
                    `}
                  >
                    Browse
                  </Button>
                </Upload>
              </div>
              <div tw="text-xs text-black">
                <p
                  css={css`
                    color: ${isFileError ? "red" : "black"};
                  `}
                >
                  <span tw="font-bold">Minimum File Size:</span> 45KB
                  <span tw="font-bold ml-4">Maximum File Size:</span> 10MB
                </p>
                <p>
                  <span tw="font-bold">Acceptable Formats:</span> 4-Color (CMYK)
                  .JPG Only
                </p>
              </div>
            </div>

            <div
              css={[
                css`
                  ${tw`flex flex-wrap overflow-y-auto overflow-x-hidden pl-4 w-7/12`}

                  max-height: 60vh;
                  & .ant-radio-group {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                  }
                  & .ant-radio-wrapper {
                    width: 100%;
                    height: 100%;
                    margin: 0 0 1rem 1rem;
                  }
                  & .ant-radio {
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    z-index: 20;
                  }
                  & .ant-radio-inner::after {
                    background-color: #000;
                  }
                  & .ant-row.ant-form-item {
                    width: 100%;
                  }

                  .choose-logo-cover {
                    background-color: rgba(0, 0, 0, 0.4);

                    ${tw`absolute top-0 right-0 bottom-0 left-0 hidden z-20`}
                  }

                  .item {
                    ${tw`flex w-1/3 h-32 mb-6`}

                    &:hover {
                      .choose-logo-cover {
                        ${tw`flex items-center justify-center text-white font-bold`}
                      }
                    }
                  }
                `,
              ]}
            >
              <Form.Item name="selected_image">
                <Radio.Group>
                  {sortedImages &&
                    sortedImages.map(item => (
                      <div
                        key={item.id}
                        className="item"
                        onClick={() => {
                          setImageUrl(item.publicFileUrl)
                          setImageName(item.filename)
                          setOriginFile(undefined)
                        }}
                      >
                        <Radio value={item.publicFileUrl}>
                          <div className="choose-logo-cover">
                            <span>CHOOSE LOGO</span>
                          </div>
                          <div
                            css={[
                              tw`bg-contain bg-no-repeat bg-center w-full h-full border border-gray-400 absolute top-0 z-10 `,
                              css`
                                background-image: url(${item.publicFileUrl});
                              `,
                            ]}
                          ></div>
                        </Radio>
                      </div>
                    ))}
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div>
            <h4 tw="text-sm text-black font-bold my-4">
              Please add tagging to the attached asset.
            </h4>
            <div
              css={css`
                .select {
                  ${tw`w-40 ml-4`}

                  ${tw`font-bold`}

                & .ant-select-selector {
                    ${tw`rounded-3xl!`}
                  }
                  & :hover {
                    & .ant-select-selector {
                      border-color: #d9d9d9 !important;
                    }
                  }
                }

                .select-multiple {
                  ${tw`w-40 text-xs ml-4`}
                  & .dropdown-container {
                    border-radius: 50px;
                  }
                  & .dropdown-content {
                    width: 300px !important;
                    z-index: 100;
                  }
                }
                ${tw`flex justify-between`}
              `}
            >
              <div tw="flex justify-between items-center">
                <h6 tw="text-xs font-bold">Tag to Location</h6>
                <MultiSelect
                  options={locationOptions}
                  value={selectedTagLocationKeys}
                  onChange={setSelectedTagLocationKeys}
                  labelledBy="Tag to Locations"
                  className="select-multiple"
                />
              </div>

              <div tw="flex justify-between items-center">
                <h6 tw="text-xs font-bold">By Provider</h6>
                <MultiSelect
                  options={providerOptions}
                  value={selectedProviderKeys}
                  onChange={setSelectedProviderKeys}
                  labelledBy="By Provider"
                  className="select-multiple"
                />
              </div>

              <div tw="flex justify-between items-center">
                <Checkbox
                  css={css`
                    .ant-checkbox-checked .ant-checkbox-inner {
                      ${tw`bg-black border-black`}
                    }

                    .ant-checkbox .ant-checkbox-inner,
                    .ant-checkbox-checked::after {
                      border-color: black;
                    }

                    ${tw`text-xs text-black font-bold`}
                  `}
                  checked={isMultipleLocations}
                  onChange={() => setIsMultipleLocations(!isMultipleLocations)}
                >
                  Apply to Multiple Locations
                </Checkbox>

                <MultiSelect
                  options={locationMultipleOptions}
                  value={selectedLocationKeys}
                  onChange={setSelectedLocationKeys}
                  labelledBy="Multiple Locations"
                  className="select-multiple"
                  disabled={!isMultipleLocations}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </CustomModal>
  )
}

const Title: React.FC = () => {
  return (
    <div tw="flex py-4 px-16 items-center">
      <h1
        css={css`
          color: #1d2b58;

          ${tw`text-lg font-bold`}
        `}
      >
        Custom Corporate Assets
      </h1>
      <p
        css={css`
          ${tw`text-xs text-black font-bold ml-16`}
        `}
      >
        Choose from one of custom assets to add or change an image on the front
        or back of your postcards.
      </p>
    </div>
  )
}

const Footer: React.FC<FooterProps> = ({
  loading,
  mappingLogoImage,
  onCancel,
  onSubmit,
}) => {
  return (
    <div
      css={css`
        margin-top: 30px;

        ${tw`flex justify-end pr-12 py-5 items-center`}

        button {
          ${tw`w-full font-bold`}
        }
      `}
    >
      <p
        css={css`
          color: #f37e17;
          max-width: 360px;

          ${tw`font-bold text-xs mr-5`}
        `}
      >
        ATTENTION: Changes made here will permanently affect your Global Asset
        Mapping for this location.
      </p>

      {mappingLogoImage &&
        [MappingLogoImage.BACK_IMAGE, MappingLogoImage.FRONT_IMAGE].includes(
          mappingLogoImage
        ) && (
          <Button
            size="large"
            css={css`
              max-width: 250px;
              color: black;
            `}
            shape="round"
          >
            PUBLIC LIBRARY &#10095;
          </Button>
        )}

      <Button
        size="large"
        css={css`
          max-width: 144px;
          color: black;
        `}
        shape="round"
        onClick={onCancel}
      >
        CANCEL
      </Button>

      <Button
        size="large"
        shape="round"
        css={css`
          background-color: #999999;
          max-width: 200px;

          ${tw`text-white`}
        `}
        onClick={onSubmit}
        loading={loading}
      >
        SUBMIT
      </Button>
    </div>
  )
}
