import React from "react"
import css from "@emotion/css"
import { Button, Input } from "antd"
import tw from "twin.macro"

import CustomModal from "@src/components/ui/modal"

interface WebLinkModalProps {
  isVisible: boolean
  webLink: string
  error?: string
  setWebLink: (value: string) => void
  onCancel: () => void
  handleSubmit: () => void
}

export const WebLinkModal: React.FC<WebLinkModalProps> = ({
  isVisible,
  webLink,
  error,
  setWebLink,
  onCancel,
  handleSubmit,
}) => {
  return (
    <CustomModal
      title={
        <span
          css={css`
            color: #1d2b58;

            ${tw`flex justify-center font-bold text-lg`}
          `}
        >
          ENTER WEBLINK
        </span>
      }
      isVisible={isVisible}
      onCancel={onCancel}
      footer={<></>}
    >
      <div
        css={css`
          padding: 28px;
          ${tw`text-center text-black`}
        `}
      >
        <p tw="text-base mb-4">
          Please take a moment to enter your location Web Link.
        </p>
        <Input
          css={css`
            max-width: 400px;

            ${tw`w-full rounded-full px-6`}
          `}
          size="large"
          placeholder="Web link"
          value={webLink}
          onChange={event => setWebLink(event.target.value)}
        />
        <p
          css={{
            color: "red",
            fontSize: "14px",
          }}
        >
          {error}
        </p>
        <div
          css={css`
            margin-top: 30px;

            ${tw`flex justify-around`}

            button {
              max-width: 200px;

              ${tw`w-full font-bold`}
            }
          `}
        >
          <Button size="large" shape="round" onClick={onCancel}>
            CANCEL
          </Button>

          <Button
            size="large"
            shape="round"
            css={css`
              background-color: #999999;

              ${tw`text-white`}
            `}
            onClick={handleSubmit}
          >
            SAVE
          </Button>
        </div>
      </div>
    </CustomModal>
  )
}
