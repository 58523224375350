import React from "react"

import Layout from "@src/components/layout"
import MappingSection from "@src/sections/mapping"


const Mapping = (): React.ReactElement => {
  return (
    <Layout>
      <MappingSection />
    </Layout>
  )
}

export default Mapping
