import CustomContainer from "@src/components/customContainer"
import MappingNavigation from "@src/components/mapping/navigation"
import { Button, Table, Select, Input } from "antd"
import { ColumnType } from "antd/lib/table"
import React from "react"
import tw, { css } from "twin.macro"
import { isEmpty } from "lodash/fp"
import { InfoCircleOutlined } from "@ant-design/icons"
import { CaretDownOutlined } from "@ant-design/icons"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import MappingLogo from "@src/assets/mapping-logo.svg"
import MappingImage from "@src/assets/mapping-image.svg"
import { GET_USER, User, Location } from "@src/queries/user"
import CustomModal from "@src/components/ui/modal"
import {
  LocationGalleryMap,
  GET_LOCATION_GALLERY_MAPS,
  CREATE_LOCATION_GALLERY_MAP,
} from "@src/queries/locationGalleryMap"
import { WebLinkModal } from "./webLinkModal"
import { ImageModal, MappingLogoImage } from "./imageModal"
import {
  LocationGalleryMapping,
  CREATE_LOCATION_GALLERY_MAPPING,
  UPDATE_LOCATION_GALLERY_MAPPING_MASS,
  GET_LOCATION_GALLERY_MAPPINGS_BY_LOCATION_GALLERY_MAP_ID,
} from "@src/queries/locationGalleryMapping"
import { getLocMapIdFromSession } from "@src/utils/storage"

const { Option } = Select

const Mapping = (): React.ReactElement => {
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [isOpenWebLinkModal, setIsOpenWebLinkModal] = React.useState<boolean>(
    false
  )
  const [isOpenImageModal, setIsOpenImageModal] = React.useState<boolean>(false)
  const [locationGalleryMap, setLocationGalleryMap] = React.useState<
    LocationGalleryMap
  >()
  const [locationGalleryMapId, setLocationGalleryMapId] = React.useState<
    number
  >()
  const [locationGalleryMapName, setLocationGalleryMapName] = React.useState<
    string
  >("")
  const [error, setError] = React.useState<string>("")
  const [mappingErrors, setMappingErrors] = React.useState<number>(0)
  const [webLink, setWebLink] = React.useState<string>("")
  const [location, setLocation] = React.useState<Location>()

  //
  const [mappingLogoImage, setMappingLogoImage] = React.useState<
    MappingLogoImage
  >()

  // get user
  const getUserResp = useQuery(GET_USER)

  const user: User =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)

  // get locationGalleryMaps
  const getLocationGalleryMapsResp = useQuery(GET_LOCATION_GALLERY_MAPS)

  const locationGalleryMaps: LocationGalleryMap[] =
    getLocationGalleryMapsResp &&
    getLocationGalleryMapsResp.data &&
    getLocationGalleryMapsResp.data.locationGalleryMaps

  // create locationGalleryMap
  const [createLocationGalleryMap] = useMutation(CREATE_LOCATION_GALLERY_MAP)

  // create locationGalleryMapping
  const [createLocationGalleryMapping] = useMutation(
    CREATE_LOCATION_GALLERY_MAPPING
  )

  // update locationGalleryMappingMass
  const [updateLocationGalleryMappingMass] = useMutation(
    UPDATE_LOCATION_GALLERY_MAPPING_MASS
  )

  // get locationGalleryMappingsByLocationGalleryMapId
  const [
    getLocationGalleryMappingsByLocationGalleryMapId,
    { data, refetch },
  ] = useLazyQuery(GET_LOCATION_GALLERY_MAPPINGS_BY_LOCATION_GALLERY_MAP_ID, {
    variables: { locationGalleryMapId },
  })

  const locationGalleryMappingsByLocationGalleryMapId: LocationGalleryMapping[] =
    data && data.locationGalleryMappingsByLocationGalleryMapId

  const renderFrontBack = (
    value: string,
    mappingLogoImage: MappingLogoImage,
    record: Location,
    isEmpty?: boolean,
    isLogo?: boolean
  ) => (
    <Button
      size="large"
      shape="round"
      css={css`
        padding: 10px 12px;
        color: ${isEmpty ? "#dc2727" : "#000"};
        border-color: ${isEmpty ? "#dc2727" : "#000"};
        &:hover {
          g {
            fill: #000;
          }
        }

        ${tw`flex items-center`}
      `}
      icon={
        <span
          css={css`
            g {
              fill: ${isEmpty ? "#dc2727" : "#000"};
            }
          `}
        >
          {isLogo ? <MappingLogo /> : <MappingImage />}
        </span>
      }
      title={value}
      onClick={() => {
        setLocation(record)
        setIsOpenImageModal(true)
        setMappingLogoImage(mappingLogoImage)
      }}
    >
      <span tw="truncate ml-1 font-bold">{value}</span>
    </Button>
  )

  const columns: ColumnType<Location>[] = [
    {
      key: "orgName",
      dataIndex: "orgName",
      // eslint-disable-next-line react/display-name
      render: (value: string) => {
        return (
          <span
            css={css`
              min-width: 130px;
              ${tw`font-bold truncate flex justify-center w-full`}
            `}
            title={value}
          >
            {value}
          </span>
        )
      },
    },
    {
      key: "city",
      dataIndex: "city",
      // eslint-disable-next-line react/display-name
      render: (value: string, record) => {
        const newValue =
          record.state || record.zip
            ? `${value}, ${record.state} ${record.zip}`
            : value

        return (
          <span
            css={css`
              min-width: 130px;
              ${tw`font-bold flex truncate w-full`}
            `}
            title={newValue}
          >
            {newValue}
          </span>
        )
      },
    },
    {
      key: "logoFront",
      dataIndex: "logoFront",
      // eslint-disable-next-line react/display-name
      render: (_: string, record, index) => (
        <div
          css={css`
            ${tw`grid grid-cols-5`}

            gap: 0 8px;
          `}
        >
          {renderFrontBack(
            "Logo Front",
            MappingLogoImage.FRONT_LOGO,
            record,
            isEmpty(getMappingByLocKey(record.locKey)?.frontLogoPublicUrl) &&
              index === 0,
            true
          )}
          {renderFrontBack(
            "Logo Back",
            MappingLogoImage.BACK_LOGO,
            record,
            isEmpty(getMappingByLocKey(record.locKey)?.backLogoPublicUrl) &&
              index === 0,
            true
          )}
          {renderFrontBack(
            "Image Front",
            MappingLogoImage.FRONT_IMAGE,
            record,
            isEmpty(getMappingByLocKey(record.locKey)?.frontPhotoPublicUrl) &&
              index === 0
          )}
          {renderFrontBack(
            "Image Back",
            MappingLogoImage.BACK_IMAGE,
            record,
            isEmpty(getMappingByLocKey(record.locKey)?.backPhotoPublicUrl) &&
              index === 0
          )}

          <Button
            size="large"
            shape="round"
            css={css`
              padding: 10px 12px;
              color: ${isEmpty(getMappingByLocKey(record.locKey)?.webLink) &&
              index === 0
                ? "#dc2727"
                : "#000"};
              border-color: ${isEmpty(
                getMappingByLocKey(record.locKey)?.webLink
              ) && index === 0
                ? "#dc2727"
                : "#000"};
            `}
            onClick={() => {
              setLocation(record)
              setIsOpenWebLinkModal(true)
              clickToGetMappingByLocKey(record.locKey)
            }}
          >
            <span tw="font-bold truncate font-bold">Web link</span>
          </Button>
        </div>
      ),
    },
  ]

  const getMappingByLocKey = (
    locKey: string | null
  ): LocationGalleryMapping | undefined => {
    if (!locationGalleryMappingsByLocationGalleryMapId || !locationGalleryMapId)
      return undefined

    const index = locationGalleryMappingsByLocationGalleryMapId.findIndex(
      mapping =>
        mapping.locKey === locKey &&
        mapping.locationGalleryMapId === locationGalleryMapId
    )

    return index >= 0
      ? locationGalleryMappingsByLocationGalleryMapId[index]
      : undefined
  }

  const clickToGetMappingByLocKey = (locKey: string | null): void => {
    const mapping = getMappingByLocKey(locKey)

    if (mapping) {
      setWebLink(mapping.webLink)
    }
  }

  const getLocationGalleryMaps = (): LocationGalleryMap[] => {
    if (locationGalleryMaps) {
      return locationGalleryMaps
    }

    return []
  }

  const getLocations = (): Location[] => {
    if (user && user.locations) {
      const newLocations = Object.assign([], user.locations)

      newLocations.unshift({
        locKey: null,
      } as any)

      return newLocations
    }

    return []
  }

  const getMappingErrors = () => {
    if (locationGalleryMappingsByLocationGalleryMapId && getLocations()) {
      let allErrors = 0

      const mappingByLocKeyNull = locationGalleryMappingsByLocationGalleryMapId.find(
        mapping =>
          !mapping.locKey &&
          mapping.locationGalleryMapId === locationGalleryMapId
      )

      if (!mappingByLocKeyNull) {
        allErrors = 5
      } else {
        if (!mappingByLocKeyNull["frontLogoPublicUrl"]) {
          allErrors += 1
        }
        if (!mappingByLocKeyNull["backLogoPublicUrl"]) {
          allErrors += 1
        }
        if (!mappingByLocKeyNull["frontPhotoPublicUrl"]) {
          allErrors += 1
        }
        if (!mappingByLocKeyNull["backPhotoPublicUrl"]) {
          allErrors += 1
        }
        if (!mappingByLocKeyNull["webLink"]) {
          allErrors += 1
        }
      }

      setMappingErrors(allErrors)
    }
  }

  const handleCreateLocationGalleryMap = () => {
    createLocationGalleryMap({
      variables: {
        body: {
          coverKey: user.coverKey,
          name: locationGalleryMapName,
        },
      },
      refetchQueries: [
        {
          query: GET_LOCATION_GALLERY_MAPS,
        },
      ],
    })
      .then(result => setLocationGalleryMap(result.data.locationGalleryMap))
      .catch(error => console.log(error))
      .finally(() => {
        if (refetch) {
          refetch()
        }
        setIsOpenModal(false)
        setLocationGalleryMapName("")
      })
  }

  const handleSubmitWebLink = () => {
    let body = {
      locationGalleryMapId,
      coverKey: user.coverKey,
      webLink,
    }
    if (location?.locKey) {
      body = Object.assign(body, { locKey: location.locKey })
    }
    createLocationGalleryMapping({
      variables: {
        body,
      },
    })
      .then(() => {
        if (refetch) {
          refetch()
        }
        setWebLink("")
        setIsOpenWebLinkModal(false)
      })
      .catch(error => setError(error.message))
  }

  const handleSubmitLogoImage = async (
    imageName: string,
    imageUrl: string,
    locKeys: string[]
  ) => {
    const body: any = {
      locationGalleryMapId,
      coverKey: user.coverKey,
    }

    if (!mappingLogoImage) return

    body[`${mappingLogoImage}Name`] = imageName
    body[`${mappingLogoImage}PublicUrl`] = imageUrl

    if (locKeys.length > 0) {
      await updateLocationGalleryMappingMass({
        variables: {
          body: {
            ...body,
            locKeys,
          },
        },
      })
    }

    if (refetch) refetch()
  }

  const getMappingByLocKeyAndMapId = (): LocationGalleryMapping | undefined => {
    if (locationGalleryMappingsByLocationGalleryMapId && location) {
      const mapping = locationGalleryMappingsByLocationGalleryMapId.find(
        item =>
          location.locKey === item.locKey &&
          locationGalleryMapId === item.locationGalleryMapId
      )

      return mapping
    }

    return undefined
  }

  React.useEffect(() => {
    getMappingErrors()
  }, [
    locationGalleryMappingsByLocationGalleryMapId,
    user,
    locationGalleryMapId,
  ])

  React.useEffect(() => {
    if (getLocationGalleryMaps().length > 0 && !locationGalleryMapId) {
      if (getLocMapIdFromSession() && getLocMapIdFromSession() !== -1) {
        setLocationGalleryMapId(getLocMapIdFromSession())
      } else {
        setLocationGalleryMapId(getLocationGalleryMaps()[0].id)
      }

      getLocationGalleryMappingsByLocationGalleryMapId()
    } else {
      if (locationGalleryMap) {
        setLocationGalleryMapId(locationGalleryMap.id)
        if (refetch) refetch()
      }
    }
  }, [locationGalleryMaps])

  return (
    <CustomContainer customCss={tw`bg-white`}>
      <div css={[tw`flex flex-col w-full`]}>
        {/* Mapping navigation */}
        <MappingNavigation />
      </div>
      {mappingErrors > 0 && (
        <div
          css={css`
            ${tw`flex justify-center items-center mb-4`};

            color: #dc2727;
          `}
        >
          <InfoCircleOutlined
            css={css`
              ${tw`text-xl`};
            `}
          />

          <p css={tw`text-xs font-bold mx-2`}>
            Please address all Mapping Errors in data mapping before finalizing
            any campaigns.
          </p>
        </div>
      )}
      <div tw="flex flex-col self-center w-full xl:w-full">
        <Table
          css={css`
            .ant-table-title {
              ${tw`p-0`}

              border-radius: 0.5rem 0.5rem 0 0;
              border: 1px solid #f0f0f0 !important;
              border-right: ${getLocations().length > 7
                ? "none !important"
                : ""};
              border-bottom: ${getLocations().length <= 7
                ? "none !important"
                : ""};
            }

            .ant-table {
              border-top-right-radius: 0.5rem;
              border-right: ${getLocations().length > 7
                ? "1px solid #f0f0f0"
                : ""};

              .ant-table-cell {
                padding: 8px 16px;
              }
            }

            .ant-table-container {
              ${tw`max-h-md overflow-y-auto`}

              ${getLocations().length > 7
                ? tw`pr-2 mr-2 border-t-0!`
                : tw``}

              ::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                ${tw`rounded-xl`};
              }
              &::-webkit-scrollbar-thumb {
                ${tw`rounded-xl`};
                background-color: #d8d8d8;
              }
              &::-webkit-scrollbar-thumb:hover {
                ${tw`bg-gray-400`};
              }
            }
          `}
          title={() => (
            <div tw="flex">
              <div
                css={css`
                  padding-top: 14px;
                  padding-bottom: 14px;

                  ${tw`flex flex-row items-center px-4`}
                `}
              >
                {getLocationGalleryMaps().length > 0 ? (
                  <Select
                    css={css`
                      width: 100%;
                      min-width: 200px;

                      ${tw`font-bold`}

                      & .ant-select-selector {
                        ${tw`rounded-3xl!`}
                      }
                      & :hover {
                        & .ant-select-selector {
                          border-color: #d9d9d9 !important;
                        }
                      }
                    `}
                    size="large"
                    placeholder="Default Asset Mapping Set"
                    suffixIcon={<CaretDownOutlined />}
                    value={locationGalleryMapId}
                    onChange={value => setLocationGalleryMapId(value)}
                    dropdownRender={menu => (
                      <div>
                        {menu}

                        <p
                          css={css`
                            padding: 5px 12px;
                            ${tw`font-bold text-base cursor-pointer`}
                          `}
                          onClick={() => setIsOpenModal(true)}
                        >
                          New Mapping
                        </p>
                      </div>
                    )}
                  >
                    {getLocationGalleryMaps().map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Button
                    size="large"
                    shape="round"
                    css={css`
                      padding: 10px 12px;

                      ${tw`flex items-center`}
                    `}
                    onClick={() => setIsOpenModal(true)}
                  >
                    <span tw="truncate ml-1 font-bold">Create Mapping</span>
                  </Button>
                )}
              </div>

              <div tw="flex items-center justify-between w-full pr-4">
                <span tw="pl-3 border-l border-gray-400">
                  Only <strong>authorized personel</strong> should modify Global
                  Data.{" "}
                  <a
                    tw="text-black font-bold"
                    href="mailto:support@4patientcare.com"
                  >
                    If you are unsure, please contact support
                  </a>
                  .
                </span>
                <span
                  css={css`
                    ${tw`text-green-600 font-bold text-right`}

                    color: ${mappingErrors > 0 && "#dc2727"};
                  `}
                >
                  {mappingErrors} Mapping Errors
                </span>
              </div>
            </div>
          )}
          columns={columns}
          dataSource={getLocationGalleryMaps().length > 0 ? getLocations() : []}
          showHeader={false}
          bordered={true}
          pagination={false}
          rowKey="locKey"
        />
      </div>

      <CustomModal
        title={
          <span
            css={css`
              color: #1d2b58;

              ${tw`flex justify-center font-bold text-lg`}
            `}
          >
            CUSTOM ASSET MAPPING SET
          </span>
        }
        isVisible={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false)
          setLocationGalleryMapName("")
        }}
        footer={null}
      >
        <div
          css={css`
            padding: 28px;
            ${tw`text-center text-black`}
          `}
        >
          <p tw="text-base">
            Changing any asset on a template automatically creates a custom
            asset mapping set. Please take a moment to name this custom mapping
            set.
          </p>
          <p tw="font-bold my-4">Custom Asset Mapping set name</p>
          <Input
            css={css`
              max-width: 400px;

              ${tw`w-full rounded-full px-6`}
            `}
            size="large"
            value={locationGalleryMapName}
            placeholder="Unnamed Mapping"
            onChange={event => setLocationGalleryMapName(event.target.value)}
          />
          <div
            css={css`
              margin-top: 30px;

              ${tw`flex justify-around`}

              button {
                max-width: 200px;

                ${tw`w-full font-bold`}
              }
            `}
          >
            <Button
              size="large"
              shape="round"
              onClick={() => {
                setIsOpenModal(false)
                setLocationGalleryMapName("")
              }}
            >
              CANCEL
            </Button>

            <Button
              size="large"
              shape="round"
              css={css`
                background-color: #999999;

                ${tw`text-white`}
              `}
              onClick={handleCreateLocationGalleryMap}
            >
              SAVE
            </Button>
          </div>
        </div>
      </CustomModal>

      <WebLinkModal
        isVisible={isOpenWebLinkModal}
        webLink={webLink}
        error={error}
        setWebLink={setWebLink}
        onCancel={() => {
          setError("")
          setWebLink("")
          setIsOpenWebLinkModal(false)
        }}
        handleSubmit={handleSubmitWebLink}
      />

      <ImageModal
        visible={isOpenImageModal}
        selectedLocation={location}
        mappingLogoImage={mappingLogoImage}
        locationGalleryMapping={getMappingByLocKeyAndMapId()}
        locations={user && user.locations}
        providers={user && user.providers}
        onCancel={() => setIsOpenImageModal(false)}
        onSubmit={handleSubmitLogoImage}
        setMappingLogoImage={setMappingLogoImage}
      />
    </CustomContainer>
  )
}

export default Mapping
